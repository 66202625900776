footer {
    background: #33131e;
    color: #fff;
    padding: 40px 0 0;
}

footer h4 {
    font-weight: 700;
    font-size: 1.3em;
    margin-bottom: 15px;
}

footer a {
    text-decoration: none;
    color: #fff;
}

footer p {
    margin-bottom: 5px;
}
.social-media{
    text-align: center;
}
.social-media i {
    font-size: 2em;
    margin-right: 30px;
}

.footer-bottom {
    text-align: center;
    margin-top: 180px;
    padding: 10px 0;
    color: white;
    font-weight: 700;
}

@media (width < 769px) {
    .footer-wrap {
        margin-bottom: 30px;
    }
    .social-media {
        margin-top: 50px;
    }
    .footer-bottom {
        margin-top: 100px;
    }
}