*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.header {
    height: 600px;
}

.banner {
    width: 100%;
    margin: auto;
    height: 720px;
    background-image: url('../../assets/img/level1-2024/landing-header.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.navbar {
    padding: 40px 0;
}

.nav-menu{
    margin: 0 auto;
}

.nav-list {
    display: flex;
    padding: 0;
    margin-bottom: 0;
    justify-content: space-between;
}

.nav-list li {
    list-style: none;
}

.nav-list li a {
    text-decoration: none;
    color: #fff;
    font-weight: 700;
    margin: 0 20px;
}

.bar-menu {
    display: none;
}

.banner-pirate {
    width: 750px;
    top: 10px;
    position: absolute; 
    left: 50%;
    transform: translateX(-50%);
    /* animation: myAnim 6s infinite 0s ease-in-out; */
}

.banner-festival {
    width: 550px;
    top: 350px;
    position: absolute;
    left: 30%;
}

@keyframes myAnim {
	0%{
		transform:scale(1) translateX(-50%);
	}
    50%{
        transform:scale(1.1) translateX(-50%);
    }
    100% {
		transform:scale(1)translateX(-50%);
	}
}

/* RESPONSIVE */
@media (width < 769px) {
    .header {
        max-height: 1200px;
    }
    .nav-list {
        display: none;
    }
    .nav-list.active {
        padding-top: 100px;
        text-align: center;
        display: block;
        position: fixed;
        top: 0px;
        right: 0px;
        width: 100%;
        height: 100vh;
        transition: 0.5s ease-in-out;
        background-color: #fff;
        opacity: 0.8;
        z-index: 1;
    }
    .nav-list li {
        margin-bottom: 20px;
    }
    .bar-menu {
        display: block;
        position: fixed;
        top: 25px;
        right: 25px;
        border-radius: 50%;
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #0088d6;
        z-index: 111;
    }
    .bar-menu i {
        font-size: 1.3em;
        color: #fff;
    }
    .banner {
        height: 600px;
    }
    .banner-pirate {
        top: 90px;
        width: 75%;
    }
    .banner-festival {
        width: 75%;
        left: 40px
    }
    .nav-list li a {
        color: #0088d6;
    }
}

@media (width > 767px) {
    .banner-pirate {
        top: 70px;
        width: 550px;
    }
    .banner {
        height: 650px;
    }
}

@media (width > 1023px){
    .navbar{
        position: fixed;
        left: 0;
        width: 100%;
        transition: 0.2s ease-in-out;
    }
    .navbar.active {
        background: #33131e;
        position: fixed;
        left: 0;
        width: 100%;
        z-index: 111;
        transition: 0.3s ease-in-out;
        padding: 20px 0;
    }
    .banner-pirate {
        top: 80px;
        width: 550px;
    }
}

@media (width > 1700px){
    .header {
        height: 1000px;
    }
    .banner {
        height: 1000px;
    }
}

@media (width > 3400px){
    .header {
        height: 1450px;
    }
    .banner {
        height: 1450px;
    }
}