.img-fluid-about {
    max-width: 100%;
    height: auto;
}

.about {
    background-size: cover;
    padding-top: 10px;
    /* background-image: url('../../assets/img/level3/img4.png'); */
    /* padding-bottom: 300px; */
}

.about-img {
    padding: 50px 350px 50px;
}

.judul-about {
    position: relative;
}

.text-about {
    text-align: center;
    color: white;
    font-size: large;
    font-weight: bolder;
    position: relative;
}

.text-icon {
    text-align: center;
    color: white;
}

.img-price {
    position: relative;
    top: 150px;
}

.about-wef h2, .why-wef h2 {
    text-align: center;
    font-size: 3em;
    font-weight: 700;
    color: #001345;
    margin-bottom: 20px;
}
.content {
    width: 1000px;
    margin: auto;
    text-align: justify;
    font-weight: 400;
    color: #5E6282;
}

.content span {
    font-weight: 700;
}

.campus-partner{
    margin-top: 100px;
}
.platinum-partner h2 {
    text-transform: uppercase;
    font-size: 1em;
    font-weight: 700;
    text-align: center;
    margin-bottom: 30px;
}
.campus-logo{
    text-align: center;

}
.campus-logo img {
    margin: 0 20px;
}
.campus-logo2{
    text-align: center;
    width: 1200px;
    margin: 40px auto 0;
}
.campus-logo2 img {
    width: 100px;
    margin: 10px 5px 0;
}
.campus-logo2 .icla {
    width: 70px;
}
.campus-logo2 .lsbf {
    width: 130px;
}

.super-student {
    background-color: #FF013E;
    background-repeat: no-repeat;
    background-size: contain;
    width: 90%;
    border-radius: 50px;
    padding: 30px 100px;
    margin: 100px auto 0;
}
.super-student p {
    color: #fff;
    margin-bottom: 5px;
}
.super-student ol {
    color: #fff;
}
.tutorial p {
    font-size: 1em;
    font-weight: 700;
}
.scan {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.barcode {
    width: 200px;
    height: 200px;
    background: #000;
    margin: 0 10px;
}

.point-section {
    width: 90%;
    margin: 90px auto 0;

}
.card-point {
    /* background: #000; */
    text-align: center;
}
.card-point img {
    width: 200px;
}
.card-point h2 {
    font-size: 1.4em;
    font-weight: 700;
    color: #001345;
    margin-top: 20px;
}
.card-point p {
    color: #5E6282;
    /* font-weight: 700; */
}

@media (width < 769px) {
    .about-wef h2, .why-wef h2 {
        font-size: 2.5em;
    }
    .content {
        font-size: 1.1em;
        width: 90%;
    }

    .campus-logo{
        text-align: center;
    
    }
    .campus-logo img {
        width: 80px;
        margin: 0 20px;
    }
    .campus-logo2{
        text-align: center;
        width: 300px;
        margin: 40px auto 0;
    }
    .campus-logo2 img {
        width: 90px;
        margin: 10px 5px 0;
    }
    .super-student {
        padding: 50px 20px;
        margin-bottom: 90px;
        background-color: rgb(255,1,62);
        background-image: none;
    }
    .tutorial p {
        text-align: center;
        margin-bottom: 15px;
    }
    .scan {
        flex-direction: column;
        margin-top: 30px;
    }
    .scan img {
        width: 300px;
    }
    .barcode:nth-child(2){
        margin-top: 10px;
        background: green;
    }
    .card-point {
        margin-bottom: 50px;
    }
    .card-point h2 {
        margin-top: 10px;
    }
}

@media (width > 767px) {
    .about-wef {
        margin-top: 70px;
    }
    .about-wef h2, .why-wef h2 {
        width: 500px;
        margin: 0 auto 20px;
        font-size: 2.5em;
    }
    .campus-logo img {
        height: 100px;
    }
    .campus-logo2{
        width: 500px;
    }
    .card-point h2{
        width: 300px;
        margin: auto;
    }
    .card-point p {
        width: 300px;
        margin: auto;
    }
    .super-student{
        padding: 60px;
    }
}

@media (width > 1023px) {
    .about-wef h2, .why-wef h2 {
        width: 100%;
        margin: 0 auto 20px ;
    }
    .content{
        width: 800px;
    }
    .campus-logo2{
        width: 1000px;
    }
    .super-student {
        margin-bottom: 100px;
        padding: 30px 100px;
    }
    .scan img{
        position: relative;
        top: 0px;
        left: 50px;
    }
    .card-point img {
        width: 100px;
        margin: auto;
    }
    .card-point h2 {
        font-size: 1em;
        font-weight: 700;
        color: #001345;
        margin-top: 20px;
        width: 100%;
    }
    .card-point p {
        width: 100%;
    }
    .judul-about {
        margin-top: -125px;
    }
}

@media (width > 1439px) {
    .campus-logo2{
        width: 1200px;
    }
    .card-point img {
        width: 200px;
    }
    .card-point h2 {
        font-size: 1.4em;
        font-weight: 700;
        color: #001345;
        margin-top: 20px;
    }
    .card-point p {
        color: #5E6282;
        /* font-weight: 700; */
    }
}